import React from 'react';
import { bool, func } from 'prop-types';

import { FieldCheckboxGroup } from '../../../components';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { brandPrivacyPolicyUrl } from '../../../config/configMarketing';

import css from './MarketingToggle.module.css';

const MarketingToggle = props => {
  const { intl, onChange, marketingConsent } = props;

  const brandNameOriginal = process.env.REACT_APP_BRAND_NAME;
  
  function transformBrandName(input) {
    return input
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' '); 
  }

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    onChange(isChecked);
  };

  const brandPrivacyLink = (
    <a
      href={brandPrivacyPolicyUrl}
      className={css.privacyLink}
      target="_blank" 
      rel="noopener noreferrer" 
    >
      <FormattedMessage id="AuthenticationPage.termsAndConditionsBrandPrivacyLinkText" />
    </a>
  );

  return (
    <div className={css.root}>
      <FieldCheckboxGroup
        name="marketing"
        id="marketing-accepted"
        optionLabelClassName={css.finePrint}
        options={[
          {
            key: 'marketing',
            label: intl.formatMessage(
              { id: 'AuthenticationPage.marketingConsentText' }, { brandName: transformBrandName(brandNameOriginal), brandPrivacyLink: brandPrivacyLink}
            ),
          },
        ]}
        onChange={handleToggleChange}
        checked={marketingConsent}
      />
    </div>
  );
};

MarketingToggle.propTypes = {
  intl: intlShape.isRequired,
  marketingConsent: bool.isRequired,
  onChange: func.isRequired,
};

export default MarketingToggle;